import { memo, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import ReCAPTCHA from "react-google-recaptcha";
import SignatureCanvas from "react-signature-canvas";
import clsx from "clsx";
import TextareaAutosize from "react-textarea-autosize";

const Checkbox = memo(({ label, ...props }) => (
  <div className="checkbox-container">
    <label className="checkbox-label">
      <input {...props} type="checkbox" className="checkbox-input" />
      <div className={clsx("checkbox-box", { disabled: props.disabled })} />
      <p className="checkbox-text">{label}</p>
    </label>
  </div>
));

Checkbox.displayName = "Checkbox";

const App = () => {
  const [values, setValues] = useState({
    company: "",
    siret: "",
    full_name: "",
    function: "",
    signature: "",
    recaptcha: "",
    cda: false,
    options: [],
    symfony_total: "",
    laravel_total: "",
    javascript_total: "",
    custom: false,
    custom_technology: "",
    custom_methodology: "",
    custom_planned_text: "",
    custom_remote: false,
    custom_planned: false,
    custom_support: false,
  });
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const date = useMemo(() => format(new Date(), "dd/MM/yyyy"), []);
  const signatureRef = useRef(null);
  const recaptchaRef = useRef(null);

  const onCheck = (event, value) => {
    const newValue = [...values.options];

    if (event.target.checked) {
      newValue.push(value);

      return setValues((rest) => ({ ...rest, options: newValue }));
    } else {
      const index = newValue.indexOf(value);

      if (index !== -1) {
        newValue.splice(index, 1);
      }

      return setValues((rest) => ({ ...rest, options: newValue }));
    }
  };

  const onChange = (key, value) => {
    return setValues((rest) => ({ ...rest, [key]: value }));
  };

  const onSign = () => {
    const signature = signatureRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");

    return onChange("signature", signature);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (values?.success) {
      setValues((rest) => ({ ...rest, success: false }));
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/recruitment/numerilis`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then(({ status, message, data }) => {
        if (status === 200) {
          signatureRef.current.clear();

          setValues({
            company: "",
            siret: "",
            full_name: "",
            function: "",
            signature: "",
            recaptcha: "",
            cda: false,
            custom: false,
            custom_technology: "",
            custom_methodology: "",
            custom_planned_text: "",
            custom_remote: false,
            custom_planned: false,
            custom_support: false,
            options: [],
            symfony_total: "",
            laravel_total: "",
            javascript_total: "",
            success: true,
          });

          setErrors({});
        } else {
          setErrors(data);
        }
      })
      .finally(() => {
        recaptchaRef.current.reset();
        setIsProcessing(false);
      });
  };

  return (
    <main className="container">
      <div className="image-container" />
      <h1 className="title">ATTESTATION DE SOUTIEN ENTREPRISE</h1>
      <form noValidate onSubmit={onSubmit} className="form">
        <p className="description">
          Dans le cadre d’une étude d’identification des besoins en recrutement
          à horizon fin 2023, j’atteste par la présente que la mise en place
          d’une <b>Préparation Opérationnelle à l’Emploi</b> en partenariat avec
          Pôle Emploi, Numerilis et l'organisme de formation Arinfo, répond à un
          réel besoin sur notre bassin économique et secteur d'activité pour la
          thématique suivante :
        </p>
        <div className="form-field">
          <Checkbox
            label={
              <>
                <b>
                  Développeur web secteur banque/assurance SYMFONY et approche
                  LARAVEL
                </b>{" "}
                <i>
                  (voir{" "}
                  <a
                    href="/fichiers/formation_full_symfony.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    programme
                  </a>{" "}
                  annexé)
                </i>
              </>
            }
            name="symfony"
            checked={values.options.includes("symfony")}
            onChange={(event) => onCheck(event, "symfony")}
            disabled={isProcessing}
          />
          {values.options.includes("symfony") && (
            <div className="form-row">
              <div className="form-field">
                <label className="form-label">
                  Nombre de recrutements envisagés
                </label>
                <input
                  name="symfony_total"
                  value={values?.symfony_total}
                  onChange={(event) => {
                    onChange(
                      "symfony_total",
                      !event.target.value.startsWith("0")
                        ? event.target.value.replace(/[^0-9]/g, "")
                        : ""
                    );
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", {
                    error: errors?.symfony_total,
                  })}
                />
                {errors?.symfony_total && (
                  <small className="form-error">{errors?.symfony_total}</small>
                )}
              </div>
              <div className="form-field form-field-filler" />
            </div>
          )}
        </div>
        <div className="form-field">
          <Checkbox
            label={
              <>
                <b>Développeur web secteur banque/assurance LARAVEL et REACT</b>{" "}
                <i>
                  (voir{" "}
                  <a
                    href="/fichiers/formation_laravel.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    programme
                  </a>{" "}
                  annexé)
                </i>
              </>
            }
            name="laravel"
            checked={values.options.includes("laravel")}
            onChange={(event) => onCheck(event, "laravel")}
            disabled={isProcessing}
          />
          {values.options.includes("laravel") && (
            <div className="form-row">
              <div className="form-field">
                <label className="form-label">
                  Nombre de recrutements envisagés
                </label>
                <input
                  name="laravel_total"
                  value={values?.laravel_total}
                  onChange={(event) => {
                    onChange(
                      "laravel_total",
                      !event.target.value.startsWith("0")
                        ? event.target.value.replace(/[^0-9]/g, "")
                        : ""
                    );
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", {
                    error: errors?.laravel_total,
                  })}
                />
                {errors?.laravel_total && (
                  <small className="form-error">{errors?.laravel_total}</small>
                )}
              </div>
              <div className="form-field form-field-filler" />
            </div>
          )}
        </div>
        <div className="form-field">
          <Checkbox
            label={
              <>
                <b>
                  Développeur web secteur banque/assurance APIs Front et Back :
                  LARAVEL et REACT
                </b>{" "}
                <i>
                  (voir{" "}
                  <a
                    href="/fichiers/formation_full_JS.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    programme
                  </a>{" "}
                  annexé)
                </i>
              </>
            }
            name="javascript"
            checked={values.options.includes("javascript")}
            onChange={(event) => onCheck(event, "javascript")}
            disabled={isProcessing}
          />
          {values.options.includes("javascript") && (
            <div className="form-row">
              <div className="form-field">
                <label className="form-label">
                  Nombre de recrutements envisagés
                </label>
                <input
                  name="javascript_total"
                  value={values?.javascript_total}
                  onChange={(event) => {
                    onChange(
                      "javascript_total",
                      !event.target.value.startsWith("0")
                        ? event.target.value.replace(/[^0-9]/g, "")
                        : ""
                    );
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", {
                    error: errors?.javascript_total,
                  })}
                />
                {errors?.javascript_total && (
                  <small className="form-error">
                    {errors?.javascript_total}
                  </small>
                )}
              </div>
              <div className="form-field form-field-filler" />
            </div>
          )}
        </div>
        <div className="form-field">
          <Checkbox
            label={
              <>
                J'identifie également la possibilité d’accueillir un{" "}
                <b>contrat d’alternance</b> via un parcours diplômant{" "}
                <b>Concepteur développeur d'applications BAC+3.</b> Le coût
                pédagogique est 100% pris en charge par l'OPCO ATLAS.
              </>
            }
            name="cda"
            checked={values.cda}
            onChange={(event) =>
              setValues((rest) => ({ ...rest, cda: event.target.checked }))
            }
            disabled={isProcessing}
          />
        </div>
        <div className="divider" />
        <div className="form-field">
          <Checkbox
            label={
              <>
                J'identifie des besoins spécifiques (en dehors de formations
                proposées ci-dessus) de formation pour mon personnel en interne
                et/ou dans une démarche d'intégration des nouveaux
                collaborateurs, en termes de :
              </>
            }
            name="custom"
            checked={values.custom}
            onChange={(event) =>
              setValues((rest) => ({ ...rest, custom: event.target.checked }))
            }
            disabled={isProcessing}
          />
        </div>
        <div className="custom-container">
          <div className="form-field">
            <label className="form-label">
              <b>Technologie</b> : Langages de programmation, framework, SGBD,
              etc.
            </label>
            <div
              className="form-textarea-container"
              data-total={`${
                values.custom_technology?.length || 0
              } / 500 caractères`}
            >
              <TextareaAutosize
                name="custom_technology"
                minRows={5}
                maxLength={500}
                value={values.custom_technology}
                onChange={(event) =>
                  onChange("custom_technology", event.target.value)
                }
                disabled={isProcessing || !values.custom}
                className={clsx("form-textarea", {
                  error: errors?.custom_technology,
                })}
              />
            </div>
            {errors?.custom_technology && (
              <small className="form-error">{errors?.custom_technology}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">
              <b>Méthodologie</b> : Conception (UML, Merise), gestion projet
              (cycle en V, Scrum, etc).{" "}
            </label>
            <div
              className="form-textarea-container"
              data-total={`${
                values.custom_methodology?.length || 0
              } / 500 caractères`}
            >
              <TextareaAutosize
                name="custom_methodology"
                minRows={5}
                maxLength={500}
                value={values.custom_methodology}
                onChange={(event) =>
                  onChange("custom_methodology", event.target.value)
                }
                disabled={isProcessing || !values.custom}
                className={clsx("form-textarea", {
                  error: errors?.custom_methodology,
                })}
              />
            </div>
            {errors?.custom_methodology && (
              <small className="form-error">{errors?.custom_methodology}</small>
            )}
          </div>
          <label className="form-label">
            <b>Type de formation envisagée</b> :
          </label>
          <div className="form-field">
            <Checkbox
              label="Formation en distanciel"
              name="custom_remote"
              checked={values.custom_remote}
              onChange={(event) =>
                setValues((rest) => ({
                  ...rest,
                  custom_remote: event.target.checked,
                }))
              }
              disabled={isProcessing || !values.custom}
            />
          </div>
          <div className="form-field">
            <Checkbox
              label="Selon planning entreprise"
              name="custom_planned"
              checked={values.custom_planned}
              onChange={(event) =>
                setValues((rest) => ({
                  ...rest,
                  custom_planned: event.target.checked,
                }))
              }
              disabled={isProcessing || !values.custom}
            />
            {values.custom_planned && (
              <div className="form-field">
                <label className="form-label">
                  Merci d'indiquer votre période souhaitée
                </label>
                <div
                  className="form-textarea-container"
                  data-total={`${
                    values.custom_planned_text?.length || 0
                  } / 500 caractères`}
                >
                  <TextareaAutosize
                    name="custom_planned_text"
                    placeholder="J'envisage ce projet pour Novembre 2023"
                    minRows={5}
                    maxLength={500}
                    value={values.custom_planned_text}
                    onChange={(event) =>
                      onChange("custom_planned_text", event.target.value)
                    }
                    disabled={isProcessing || !values.custom}
                    className={clsx("form-textarea", {
                      error: errors?.custom_planned_text,
                    })}
                  />
                </div>
                {errors?.custom_planned_text && (
                  <small className="form-error">
                    {errors?.custom_planned_text}
                  </small>
                )}
              </div>
            )}
          </div>
          <div className="form-field">
            <Checkbox
              label="Accompagnement personnalisé"
              name="custom_support"
              checked={values.custom_support}
              onChange={(event) =>
                setValues((rest) => ({
                  ...rest,
                  custom_support: event.target.checked,
                }))
              }
              disabled={isProcessing || !values.custom}
            />
          </div>
        </div>
        <p className="description">
          Cette action de formation pourra nous permettre de sélectionner des
          candidats formés et opérationnels immédiatement.
        </p>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom entreprise :</label>
            <input
              name="company"
              value={values.company}
              onChange={(event) => onChange("company", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.company })}
            />
            {errors?.company && (
              <small className="form-error">{errors?.company}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">N° SIRET :</label>
            <input
              name="siret"
              value={values.siret}
              onChange={(event) => onChange("siret", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.siret })}
            />
            {errors?.siret && (
              <small className="form-error">{errors?.siret}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom et prénom du signataire :</label>
            <input
              name="full_name"
              value={values.full_name}
              onChange={(event) => onChange("full_name", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.full_name })}
            />
            {errors?.full_name && (
              <small className="form-error">{errors?.full_name}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">Fonction du signataire :</label>
            <input
              name="function"
              value={values.function}
              onChange={(event) => onChange("function", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.function })}
            />
            {errors?.function && (
              <small className="form-error">{errors?.function}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label>Signature</label>
            <div className="form-signature">
              <SignatureCanvas
                ref={signatureRef}
                backgroundColor="white"
                onEnd={onSign}
                canvasProps={{
                  disabled: true,
                  style: {
                    pointerEvents: isProcessing && "none",
                    width: "100%",
                    height: "100%",
                    border: "1px solid #000",
                    borderRadius: "4px",
                    borderColor: isProcessing
                      ? "hsl(0, 0%, 90%)"
                      : errors?.signature
                      ? "#ff3548"
                      : "rgb(204, 204, 204)",
                  },
                }}
              />
            </div>
            {errors?.signature && (
              <small className="form-error">{errors?.signature}</small>
            )}
          </div>
          <div className="form-field form-field-filler" />
        </div>
        <div className="form-field">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(response) => onChange("recaptcha", response)}
          />
          {errors?.recaptcha && (
            <small className="form-error">{errors?.recaptcha}</small>
          )}
        </div>
        {values?.success && (
          <div className="form-success">Attestation de soutien envoyée</div>
        )}
        <div className="form-date">Fait le {date}</div>
        <p
          className="description"
          style={{ marginTop: 10, textAlign: "center" }}
        >
          Ce formulaire ne vous engage en aucun cas à embaucher, il s’agit d’une
          simple identification des besoins futurs. Merci pour votre
          participation !
        </p>
        <button type="submit" disabled={isProcessing} className="form-button">
          {isProcessing ? "Chargement" : "Envoyer"}
        </button>
        <hr style={{ borderColor: "#f5f5fb", marginTop: 20 }} />
        <p className="form-rgpd">
          Les données recueillies par ce formulaire ont pour unique but de mener
          l'étude d’identification des besoins en recrutement à horizon fin 2023
          et ne seront pas réutilisées.
        </p>
        <div className="links">
          <a
            href="https://arinfo.fr/mentions-legales"
            target="_blank"
            rel="nopenner noreferrer"
          >
            Mentions légales
          </a>
          <a
            href="https://arinfo.fr/conditions-generales-de-prestation-et-de-vente"
            target="_blank"
            rel="nopenner noreferrer"
          >
            CGPV
          </a>
        </div>
      </form>
    </main>
  );
};

export default App;
